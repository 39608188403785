import {addClass, hasClass, throttle} from "./utils";

const animations = {
    init() {
        const _ = this;
        const animationClasses = document.querySelectorAll('.js-timeline-item');
        this.elementFromTop(animationClasses, 'active',  70, 'percent');

        window.addEventListener('scroll', throttle(function() {
            _.elementFromTop(animationClasses, 'active',  70, 'percent');
        }, 100), false);
    },

    elementFromTop(elem, classToAdd = 'active', distanceFromTop = 90, unit = 'percent', delay = 0) {
        let winY = window.innerHeight || document.documentElement.clientHeight,
            elemLength = elem.length, distTop, distPercent, distPixels, distUnit, i;

        for (let i = 0; i < elemLength; ++i) {
            distTop = elem[i].getBoundingClientRect().top;
            distPercent = Math.round((distTop / winY) * 100);
            distPixels = Math.round(distTop);
            distUnit = unit === 'percent' ? distPercent : distPixels;

            if (distUnit <= distanceFromTop) {
                this.processAnimation(elem[i], classToAdd, distanceFromTop, unit, delay, i);
            }
        }
    },

    processAnimation(elem, classToAdd, distanceFromTop, unit, delay, elemCount) {
        if (!hasClass(elem, classToAdd)) {
            if (delay) {
                setTimeout(function() {
                    addClass(elem, classToAdd);
                }, delay * elemCount);
            } else {
                addClass(elem, classToAdd);
            }
        }
    }
}

export default animations