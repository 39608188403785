export default {
    init() {
        this.toggle();
    },

    toggle() {
        const triggers = document.querySelectorAll('.toggle, .mobile-nav .menu-item-has-children');
        triggers.forEach((el) => {
            if (el.classList.contains('.menu-item-has-children')) return; // Skip if click is on child elements

            el.addEventListener('click', (e) => {
                el.classList.toggle('active');
            });
        });
    }
}

export function hasClass(el, cls) {
    if (el.className.match('(?:^|\\s)'+cls+'(?!\\S)')) { return true; }
}

export function addClass(el, cls) {
    if (!el.className.match('(?:^|\\s)'+cls+'(?!\\S)')) { el.className += ' '+cls; }
}

export function throttle(fn, ms) { // Ryan Taylor comment - https://remysharp.com/2010/07/21/throttling-function-calls
    let time, last = 0;
    return function() {
        let a = arguments, t = this, now = +(new Date), exe = function() { last = now; fn.apply(t, a); };
        clearTimeout(time);
        (now >= last + ms) ? exe() : time = setTimeout(exe, ms);
    }
}

export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}