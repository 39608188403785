/**
 * Main Script File
 */

import animations from "./animations";

Element.prototype.remove = function() {
	this.parentElement.removeChild(this);
}
NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
	for(var i = this.length - 1; i >= 0; i--) {
		if(this[i] && this[i].parentElement) {
			this[i].parentElement.removeChild(this[i]);
		}
	}
}

if (typeof Object.assign !== 'function') {
	// Must be writable: true, enumerable: false, configurable: true
	Object.defineProperty(Object, "assign", {
		value: function assign(target, varArgs) { // .length of function is 2
			'use strict';
			if (target === null || target === undefined) {
				throw new TypeError('Cannot convert undefined or null to object');
			}

			var to = Object(target);

			for (var index = 1; index < arguments.length; index++) {
				var nextSource = arguments[index];

				if (nextSource !== null && nextSource !== undefined) {
					for (var nextKey in nextSource) {
						// Avoid bugs when hasOwnProperty is shadowed
						if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
							to[nextKey] = nextSource[nextKey];
						}
					}
				}
			}
			return to;
		},
		writable: true,
		configurable: true
	});
}

// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/prepend()/prepend().md
(function (arr) {
	arr.forEach(function (item) {
		if (item.hasOwnProperty('prepend')) {
			return;
		}
		Object.defineProperty(item, 'prepend', {
			configurable: true,
			enumerable: true,
			writable: true,
			value: function prepend() {
				var argArr = Array.prototype.slice.call(arguments),
					docFrag = document.createDocumentFragment();

				argArr.forEach(function (argItem) {
					var isNode = argItem instanceof Node;
					docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
				});

				this.insertBefore(docFrag, this.firstChild);
			}
		});
	});
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
(function (arr) {
	arr.forEach(function (item) {
		if (item.hasOwnProperty('append')) {
			return;
		}
		Object.defineProperty(item, 'append', {
			configurable: true,
			enumerable: true,
			writable: true,
			value: function append() {
				var argArr = Array.prototype.slice.call(arguments),
					docFrag = document.createDocumentFragment();

				argArr.forEach(function (argItem) {
					var isNode = argItem instanceof Node;
					docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
				});

				this.appendChild(docFrag);
			}
		});
	});
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
}

// from: https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/before()/before().md
(function (arr) {
	arr.forEach(function (item) {
		if (item.hasOwnProperty('before')) {
			return;
		}
		Object.defineProperty(item, 'before', {
			configurable: true,
			enumerable: true,
			writable: true,
			value: function before() {
				var argArr = Array.prototype.slice.call(arguments),
					docFrag = document.createDocumentFragment();

				argArr.forEach(function (argItem) {
					var isNode = argItem instanceof Node;
					docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
				});

				this.parentNode.insertBefore(docFrag, this);
			}
		});
	});
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

if (!Element.prototype.matches) {
	Element.prototype.matches =
		Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;

		do {
			if (Element.prototype.matches.call(el, s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}

import jQuery from 'jquery'
import 'slick-carousel'
import 'mmenu-js'
import 'jquery-match-height'
import lozad from 'lozad'

function getElementY(query) {
	return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top
}

function doScrolling(element, duration) {
	var startingY = window.pageYOffset
	var elementY = getElementY(element)
	// If element is close to page's bottom then window will scroll only to some position above the element.
	var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
	var diff = targetY - startingY
	// Easing function: easeInOutCubic
	// From: https://gist.github.com/gre/1650294
	var easing = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
	var start

	if (!diff) return

	// Bootstrap our animation - it will get called right before next frame shall be rendered.
	window.requestAnimationFrame(function step(timestamp) {
		if (!start) start = timestamp
		// Elapsed miliseconds since start of scrolling.
		var time = timestamp - start
		// Get percent of completion in range [0, 1].
		var percent = Math.min(time / duration, 1)
		// Apply the easing.
		// It can cause bad-looking slow frames in browser performance tool, so be careful.
		percent = easing(percent)

		window.scrollTo(0, (startingY + diff * percent) - 100)

		// Proceed with animation as long as we wanted it to.
		if (time < duration) {
			window.requestAnimationFrame(step)
		}
	})
}

jQuery(document).ready(function( $ ) {
	$('.bg-img').each(function () {
		var img_src = $(this).find('img').prop('currentSrc');

		if (!img_src || img_src.length === 0) {
			img_src = $(this).find('img').attr('data-src');
		}

		$(this).attr('data-background-image', img_src);
	});

	var observer = lozad('.lozad');
	observer.observe();

	$( 'html' ).removeClass( 'no-js' )

	$('.mega-menu-ctrl, .mega-menu').hover(function() {
		var menu_id = $(this).data('menu-id');
		$('#mega-menu-'+menu_id).addClass('mega-menu--active');
		$('.mega-menu-ctrl').addClass('active');
	}, function() {
		$('.mega-menu').removeClass('mega-menu--active');
		$('.mega-menu-ctrl').removeClass('active');
	});

	$('.mega-menu__close').on('click', function() {
		$('.mega-menu').removeClass('mega-menu--active');
		$('.mega-menu-ctrl').removeClass('active');
	});

	var search_form = $('#header .search-form');

	$(search_form).find('a').on('click', function(e) {
		e.preventDefault();
		var form = $(search_form).find('form');
		if (form.hasClass('open')) {
			form.submit();
		} else {
			form.toggleClass('open');
		}
	});

	$(search_form).find('.close').on('click', function(e) {
		e.preventDefault();
		$(search_form).find('form').toggleClass('open');
	});

	const menu = new Mmenu( "#mobile-menu", {
		"extensions": [
			"position-front",
			"position-right"
		],
		navbars		: [
			{
				position	: 'bottom',
				content		: [ $('.mobile-menu-details')[0].outerHTML ]
			}
		]
	});

	// Get the API
	const api = menu.API;

	$('.menu-icon').on('click', function () {
		if ($("body").hasClass("mm-wrapper_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});

	$('.back-to-top').click(function(e){
		e.preventDefault();

		$('html').animate({
			scrollTop: 0
		}, 400);
	});

	$('.view-team-testimonials').click(function(e){
		e.preventDefault();

		doScrolling('#team-member-accordion', 1000);

		$('#team-member-accordion .testimonial:first-of-type .title').toggleClass('open');
		$('#team-member-accordion .testimonial:first-of-type .full').toggleClass('open');
	});

	$('section.insights .show-more').click(function(){
		$(this).toggleClass('open');
		$('section.insights .row:last-of-type').toggleClass('open');
	});

	$('section.our-team .show-more').click(function(){
		$(this).toggleClass('open');
		$('section.our-team .team-members').toggleClass('open');
	});

	$('section.related-content-section h2').click(function(){
		$(this).toggleClass('open');
		$(this).next().toggleClass('open');
	});

	$('.testimonial-accordion .testimonial .title').click(function(){
		$(this).toggleClass('open');
		$(this).next().toggleClass('open');
	});

	$('.testimonial-accordion .see-all').click(function(){
		$(this).toggleClass('open');
		$('.testimonial-accordion .more-testimonials').toggleClass('open');
	});

	$('.testimonial-slider .testimonials').slick({
		arrows: true,
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		speed: 500,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	});

	$('.our-team .team-members, .key-contacts .team-members').slick({
		arrows: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		speed: 500,
		responsive: [
			{
				breakpoint: 1200,
				settings: "unslick"
			}
		]
	});

	$('#accreditations .logos .wrapper, .team-member-content .container .content .accreditations .logos').slick({
		arrows: true,
		dots: false,
		slidesToShow: 2,
		slidesToScroll: 1,
		speed: 500,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: "unslick"
			}
		]
	});

	$('.team-members-loop .team-members').slick({
		arrows: true,
		dots: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		speed: 500,
		responsive: [
			{
				breakpoint: 1200,
				settings: "unslick"
			}
		]
	});

	$('.team-accreditations .accreditations').slick({
		arrows: true,
		dots: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		speed: 500,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	});

	$('.single-team_member .related-articles .container .row').slick({
		arrows: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		speed: 500,
		responsive: [
			{
				breakpoint: 1200,
				settings: "unslick"
			}
		]
	});

	$('#insight_type, #blog_category, #law_update_category, #insight_year, #service-dropdown').on('change', function () {
		var url = $(this).val(); // get selected value
		if (url) { // require a URL
			window.location = url; // redirect
		}
		return false;
	});

	$('#people_expertise, #people_filter').on('change', function (e) {
		$(this).closest('form').submit();
	});

	let map;

	function initMap($el) {
		const myLatLng = {
			lat: $el.data('lat'),
			lng: $el.data('long')
		};

		map = new google.maps.Map($el[0], {
			center: myLatLng,
			zoom: 14
		});

		new google.maps.Marker({
			position: myLatLng,
			map,
		});
	}

	const map_el = $('#map');

	if (map_el.length) {
		initMap(map_el);
	}

	$('.posts-wrapper .team-member-card-archive .details').matchHeight();
	$('.team-member-card-basic .job-position').matchHeight();

	function autoloadSlickImages() {
		$('.slick-slider').on('afterChange', function(event) {
			// const image = document.querySelectorAll('section.property-overview .gallery [data-slick-index=\'' + currentSlideIndex + '\'] .bg-img.lozad');
			// const currentSlideIndex = currentSlide+1;
			// observer.triggerLoad(image[0]);
			const images = event.delegateTarget.querySelectorAll('.lozad');
			images.forEach(function (item, index) {
				observer.triggerLoad(item);
			});
		});
	}

	autoloadSlickImages();

	const postsWrapper = document.getElementById('posts-wrapper');

	if (postsWrapper !== null) {
		const action = postsWrapper.getAttribute('data-action');
		let loadMoreEls = document.querySelectorAll('.load-more');

		initializeLoadMores(loadMoreEls);

		function initializeLoadMores(loadMoreEls) {
			loadMoreEls.forEach(function(loadMore) {
				loadMore.onclick = function(evt){
					filterPosts(loadMore.getAttribute('data-next-page'), true, loadMore.getAttribute('data-offset'));
				};
			});
		}

		function filterPosts(paged = 1, isLoadMoreClick = false, offset) {
			fetch(cdScriptsObj.ajax_url, {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Cache-Control': 'no-cache',
				},
				body: new URLSearchParams({
					action: action,
					paged: paged,
					offset: offset,
					isLoadMoreClick: isLoadMoreClick,
				}),
			}).then(response => {
				response.json().then(results => {
					postsWrapper.innerHTML += results.cards;

					loadMoreEls.forEach(function(loadMore) {
						loadMore.setAttribute('data-next-page', results.paged);
						loadMore.setAttribute('data-max-num-pages', results.max_num_pages);

						if (results.paged > results.max_num_pages) {
							loadMore.classList.add('hide');
						} else {
							loadMore.classList.remove('hide');
						}
					});
				});
			}).catch(err => console.log(err));
		}
	}

	function hasClass(el, cls) {
		if (el.getAttribute("class").match('(?:^|\\s)'+cls+'(?!\\S)')) { return true; }
	}

	function addClass(el, cls) {
		if (!el.getAttribute("class").match('(?:^|\\s)'+cls+'(?!\\S)')) { el.setAttribute('class', el.getAttribute("class")+' '+cls); }
	}

	if ($('.core-values svg.desktop').length) {
		window.addEventListener('scroll', function() {
			const elem = document.querySelector('.core-values svg.desktop');

			let winY = window.innerHeight || document.documentElement.clientHeight,
				distTop, distPercent;

			distTop = elem.getBoundingClientRect().top;
			distPercent = Math.round((distTop / winY) * 100);

			if (distPercent <= 80) {
				if (! hasClass(elem, 'active')) {
					addClass(elem, 'active');

					$('.core-values svg.desktop g').each(function(index) {
						const group = $(this);

						setTimeout(function() {
							group.addClass('active');
						}, index * 3000)
					});
				}
			}
		}, false);
	}

	$('.core-values svg.mobile g').on('click', function(e) {
		const id = $(this).attr('id');
		const target = $(`#${id}-content`);

		$('.core-values svg.mobile foreignObject').removeClass('active');
		target.addClass('active');

		$('.core-values svg.mobile').addClass('active');
	});

	$('.core-values svg.mobile .close').on('click', function(e) {
		$('.core-values svg.mobile foreignObject').removeClass('active');
		$('.core-values svg.mobile').removeClass('active');
	});

	let cookieBanner = $('.cookie-info-banner .cookie-info-banner__close');

	function readCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	if (cookieBanner.length) {
		if (readCookie('cookie_info_banner') !== '1') {
			$('.cookie-info-banner').show();
		}

		cookieBanner.on('click', function() {
			$('.cookie-info-banner').hide();

			document.cookie = "cookie_info_banner=1; path=/";
		});
	}

	function isOverlapping(element1, element2) {
		const rect1 = element1.getBoundingClientRect();
		const rect2 = element2.getBoundingClientRect();

		return !(rect1.right < rect2.left ||
			rect1.left > rect2.right ||
			rect1.bottom < rect2.top ||
			rect1.top > rect2.bottom);
	}

	const timelineBlocks = document.querySelectorAll('.js-timeline');
	let adjacentHistoryItemsSpacing = 0;

	timelineBlocks.forEach(function (timelineBlock) {
		const historyItems = timelineBlock.querySelectorAll('.js-timeline-history-item');

		historyItems.forEach(function (historyItem) {
			const previousItem = historyItem.previousElementSibling;
			const previousPreviousItem = previousItem.previousElementSibling;
			let historyItemTop = previousItem.offsetTop;

			if (window.innerWidth > 1199) {
				historyItem.style.top = (historyItemTop + 120) + 'px';

				if (previousItem && previousItem.classList.contains('js-timeline-history-item')) {
					const overlapping = isOverlapping(historyItem, previousItem);

					if (overlapping) {
						const currentTop = historyItem.offsetTop;

						historyItemTop = (previousItem.offsetTop + previousItem.offsetHeight + 40);
						historyItem.style.top = historyItemTop + 'px';

						adjacentHistoryItemsSpacing = adjacentHistoryItemsSpacing + (historyItem.offsetTop - currentTop);

						if (historyItem.nextElementSibling.classList.contains('js-timeline-company-item')) {
							historyItem.nextElementSibling.style.marginTop = (adjacentHistoryItemsSpacing - 120) + 'px';

							adjacentHistoryItemsSpacing = 0;
						}
					}
				} else if (previousPreviousItem && previousPreviousItem.classList.contains('js-timeline-history-item')) {
					const overlapping = isOverlapping(historyItem, previousPreviousItem);

					if (overlapping) {
						const currentTop = historyItem.offsetTop;

						historyItemTop = (previousPreviousItem.offsetTop + previousPreviousItem.offsetHeight + 40);
						historyItem.style.top = historyItemTop + 'px';

						previousItem.style.marginTop = (historyItem.offsetTop - currentTop) + 'px';
					}
				}

				if (
					! (previousItem && previousItem.classList.contains('js-timeline-history-item')) &&
					historyItem.nextElementSibling.classList.contains('js-timeline-history-item')
				) {
					adjacentHistoryItemsSpacing = adjacentHistoryItemsSpacing + historyItem.offsetHeight;
				}
			}
		});
	});

	animations.init();
})
